<div class="contacts">
  <app-smart-line></app-smart-line>
  <div class="inner-info">
    <div class="title item-list">
      {{'contacts' | translate}}
    </div>
    <div class="vr-line"></div>

    <div class="phones">
      <div class="phone-icon"></div>
      <div class="phone-list">
        <div class="phone">
          <a href="tel:+380978767475">+380978767475</a>
        </div>
      </div>
    </div>
    <div class="locations">
      <div class="location-icon"></div>
      <div class="location-list">
        <div class="location">
          <div class="shop">{{'contacts.bigBenName' | translate}}</div>
          <div class="street">{{'contacts.bigBenAddress' | translate}}</div>
          <div class="city"> {{'contacts.bigBenCity' | translate}}</div>

        </div>
      </div>
    </div>
  </div>

</div>
<div class="smart-hr"></div>
