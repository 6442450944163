<app-header></app-header>
<div id="wrapper-content">
    <div class="wrapper__line">
        <app-smart-line></app-smart-line>
    </div>

    <main role="main">
        <router-outlet></router-outlet>
    </main>
</div>
<app-footer></app-footer>