<div class="main-header">
  <div class="main-logo" routerLink="/"></div>
  <div class="lang-wrap">
    <div class="lang-item">
      <span class="active" (click)="toUa()" [ngClass]="{
      active: activeLang === 'uk'
    }">UA</span>
    </div>
    <div class="lang-item">
      <div class="lang-line"></div>
      <span (click)="toRu()" [ngClass]="{
      active: activeLang === 'ru'
    }">RU
      </span>
    </div>
    <div class="lang-item">
      <div class="lang-line"></div>
      <span (click)="toEn()" [ngClass]="{
      active: activeLang === 'en'
    }">EN
      </span>
    </div>
  </div>
</div>