import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-smart-line',
  templateUrl: './smart-line.component.html',
  styleUrls: ['./smart-line.component.scss']
})
export class SmartLineComponent implements OnInit {

  @Input() color:string = "#9e9e9e"
  
  constructor() { }

  ngOnInit(): void {
  }

}
