import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  bcgStatus: boolean = false
  constructor() { }
  @Input() bg;
  ngOnInit(): void {
  }

  scrollToTop() {
    window.scroll(0, 0);
  }
}
