<footer [ngClass]="{ 'Footer-bcg': bcgStatus }" class="main-footer">

	<div class="smart-list">
		<div
			(click)="bcgStatus = false"
			class="smart-item test-class"
			routerLink="/shop/mobilochka-smartphone"
			routerLinkActive="active"
		>
			<div class="color-block light-gray"></div>
			<div class="title-item"><span>магазини</span></div>
			<div class="name-item"><span>Мобілочка Смартфон</span></div>
		</div>
		<div
			(click)="bcgStatus = false"
			class="smart-item"
			routerLink="/shop/big-ben-exchange"
			routerLinkActive="active"
		>
			<div class="color-block gray"></div>
			<div class="title-item"><span>обміник</span></div>
			<div class="name-item"><span>БігБен</span></div>
		</div>
		<div
			(click)="bcgStatus = false"
			class="smart-item"
			routerLink="/shop/auto-buisness"
			routerLinkActive="active"
		>
			<div class="color-block dark-gray"></div>
			<div class="title-item"><span>автобізнес</span></div>
			<div class="name-item"><span>АвтоАктив</span></div>
		</div>
		<div
			(click)="bcgStatus = true"
			class="smart-item"
			routerLink="/shop/accountant-lawyer"
			routerLinkActive="active"
		>
			<div class="color-block light-gray"></div>
			<div class="title-item"><span>надання</span></div>
			<div class="name-item">
				<span>бухгалтерських та юридичних послуг</span>
			</div>
		</div>
		<div
			(click)="bcgStatus = false"
			class="smart-item"
			routerLink="/shop/prestige"
			routerLinkActive="active"
		>
			<div class="color-block gray"></div>
			<div class="title-item"><span>ювелірний магазин</span></div>
			<div class="name-item"><span>Престиж</span></div>
		</div>
		<div
			(click)="bcgStatus = false"
			class="smart-item"
			routerLink="/shop/big-ben-clock"
			routerLinkActive="active"
		>
			<div class="color-block dark-gray"></div>
			<div class="title-item"><span>магазин-музей годинників</span></div>
			<div class="name-item"><span>БігБен</span></div>
		</div>
	</div>

	<div class="footer__line">
		<app-smart-line></app-smart-line>
	</div>

	<footer class="footer-info">
		<nav class="social-link">
			<a href="https://www.instagram.com/mobilochka.smartlogic/" target="_blank" class="item-link insta"></a>
			<a href="https://vm.tiktok.com/Je5qHSP" target="_blank" class="item-link tik-tok"></a>
			<a href="https://www.facebook.com/tcbigben/" target="_blank" class="item-link fb"></a>
		</nav>

		<div class="right-items">
			<div class="footer-logo" routerLink="/" (click)="scrollToTop()"></div>
			<div (click)="scrollToTop()" class="to-top"></div>
		</div>
	</footer>
</footer>
