// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  locales: ['en', 'ru', 'uk'],
  defaultLocale: 'uk',
  firebaseConfig: {
    apiKey: "AIzaSyCQEsvAf7g29o1sMtbaV62zZ6FUUiX2SRA",
    authDomain: "big-ben-cash.firebaseapp.com",
    databaseURL: "https://big-ben-cash-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "big-ben-cash",
    storageBucket: "big-ben-cash.appspot.com",
    messagingSenderId: "674559777610",
    appId: "1:674559777610:web:2592b83f849ae5cc793fb3",
    measurementId: "G-8RN9TWTPNY"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
