import { Component, OnInit } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  activeLang = 'uk';
  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {}

  toUa() {
    this.activeLang = 'uk';
    this.translateService.use('uk');
  }

  toRu() {
    this.activeLang = 'ru';
    this.translateService.use('ru');
  }
  toEn() {
    this.activeLang = 'en';
    this.translateService.use('en');
  }

}
